import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {Link} from 'react-router-dom';
import dayjs from'dayjs';
//Material-UI
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';

//Icons
import LocationOn from '@material-ui/icons/LocationOn';
import LinkIcon from '@material-ui/icons/Link';
import CalendarToday from '@material-ui/icons/CalendarToday';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardReturn from '@material-ui/icons/KeyboardReturn';

//components
import EditDetails from './EditDetails';
import CustomButton from '../../util/CustomButton';
import ProfileSkeleton from '../../util/ProfileSkeleton';

//Redux+
import {connect} from 'react-redux';
import { logoutUser,uploadImage } from '../../redux/actions/userActions';


const styles = (theme) => ({
    ...theme.spreadIt
  });

class Profile extends Component {
    handleImageChange=(event)=>{
        const image=event.target.files[0];
        //send image to server
        const formData=new FormData();
        formData.append('image',image,image.name);
        this.props.uploadImage(formData);
    }
    handleLogout=()=>{
        this.props.logoutUser();
    }
    handleEditPicture=()=>{
        const fileInput= document.getElementById('imageInput');
        fileInput.click();
    }
    render() {
        const {classes,user:{credentials:{handle,createdAt,imageUrl,bio,website,location},loading,authenticated}} =this.props
        
        let profileMarkup=!loading?(authenticated?(
            <Paper className={classes.paper}>
                <div className={classes.profile}>
                    <div className="image-wrapper">
                        <img src={imageUrl} alt="profile"  className="profile-image"/>
                        <input 
                        type="file" 
                        id="imageInput" 
                        hidden="hidden"
                        onChange={this.handleImageChange}/>
                        <CustomButton tip="Change profile picture" onClick={this.handleEditPicture} btnClassName="button">
                            <EditIcon color="primary"/>
                        </CustomButton>
                    </div> 
                    <hr /> 
                    <div className="profile-details ">
                        <MuiLink component={Link} to={`/user/${handle}`} color="primary" variant="h5">
                            @{handle}
                        </MuiLink>
                        <hr />
                        {bio && <Typography variant="body2">{bio}</Typography>}
                        <hr/>
                        {location && (
                            <Fragment>
                                <LocationOn color="primary"/><span>{location}</span>
                            <hr/>
                            </Fragment>
                        )}
                        {website && (
                            <Fragment>
                                <LinkIcon color="primary"/><a href={website} target="_blank" rel="noopener noreferrer">
                                        {' '}{website}
                                    </a>
                            <hr/>
                            </Fragment>
                        )}
                        <CalendarToday color="primary"/>
                        <span> Joined On {dayjs(createdAt).format('MMM YYYY')}</span>           
                    </div>   
                    <CustomButton tip="logout" onClick={this.handleLogout}>
                            <KeyboardReturn color="primary"/>
                    </CustomButton>
                    <EditDetails/>
                </div>
            </Paper>
        ):(
            <Paper className={classes.paper}>
                <Typography variant="body2" align="center">
                    Login to Post, like and comment
                </Typography>
                <div className={classes.buttons}>
                    <Button variant="contained" color="primary" component={Link} to="/login">
                        Login
                    </Button>
                    <Button variant="contained" color="secondary" component={Link} to="/signup">
                        Signup
                    </Button>
                </div>
            </Paper>
        )):(<ProfileSkeleton/>)
        
        return profileMarkup;
    }
}
const mapStateToProps=(state)=>({
    user:state.user,
    UI:state.UI
})

const mapActionsToProps={
    logoutUser,
    uploadImage
}

Profile.propTypes={
    logoutUser:PropTypes.func.isRequired,
    uploadImage:PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,
    classes:PropTypes.object.isRequired
}

export default connect(mapStateToProps,mapActionsToProps)(withStyles(styles)(Profile))
