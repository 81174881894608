import React from 'react';
import NoImage from '../images/no-img.png';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Paper from '@material-ui/core/Paper';

//Icons
import LocationOn from '@material-ui/icons/LocationOn';
import LinkIcon from '@material-ui/icons/Link';
import CalendarToday from '@material-ui/icons/CalendarToday';

const styles = (theme) => ({
    ...theme.spreadIt,
    handle:{
        height:20,
        backgroundColor:theme.palette.primary.main,
        width:60,
        margin:'0 auto 7px auto'
    },
    fullLine:{
      height:15,
      width:'100%',
      backgroundColor:'rgba(0,0,0,0.5)',
      marginBottom:10  
    },
    halfLine:{
        height:15,
        width:'50%',
        backgroundColor:'rgba(0,0,0,0.5)',
        marginBottom:10  
      }
})

const ProfileSkeleton =(props)=> {
    const {classes}=props

    return (
        <Paper className={classes.paper}>
            <div className={classes.profile}>
                <div className="image-wrapper">
                    <img src={NoImage} alt="profile" className="profile-image"/>
                </div>
                <hr/>
                <div className="profile-details">
                    <div className={classes.handle}/>
                    <hr/>
                    <div className={classes.fullLine}/>
                    <div className={classes.fullLine}/>
                    <hr/>
                    <LocationOn color="primary"/> <span>Location</span>
                    <hr/>
                    <LinkIcon color="primary"/> https://whizpath.com
                    <hr/>
                    <CalendarToday color="primary"/> Joined Today
                </div>
            </div>
        </Paper>
    )
}
ProfileSkeleton.propTypes={
    classes:PropTypes.object.isRequired
}

export default withStyles(styles)(ProfileSkeleton)
