//User reducer types
export const SET_AUTHENTICATED='SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED='SET_UNAUTHENTICATED';
export const SET_USER='SET_USER';
export const LOADING_USER='LOADING_USER';
export const MARK_NOTIFICATIONS_READ='MARK_NOTIFICATIONS_READ';


//UI reducer types
export const SET_ERRORS='SET_ERRORS';
export const LOADING_UI='LOADING_UI';
export const CLEAR_ERRORS='CLEAR_ERRORS';
export const LOADING_DATA='LOADING_DATA';
export const STOP_LOADING_UI='STOP_LOADING_UI';

//Data reducer types
export const SHOUT_USER='SHOUT_USER';
export const SET_SHOUTS='SET_SHOUTS';
export const SET_CONTENT='SET_CONTENT';
export const SET_SHOUT='SET_SHOUT';
export const LIKE_SHOUT='LIKE_SHOUT';
export const UNLIKE_SHOUT='UNLIKE_SHOUT';
export const DELETE_SHOUT='DELETE_SHOUT';
export const POST_SHOUT='POST_SHOUT';
export const SUBMIT_COMMENT='SUBMIT_COMMENT';
