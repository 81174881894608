import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

//Redux
import {connect} from 'react-redux';
import {postShout,clearErrors} from '../../redux/actions/dataActions';

//MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

//components
import CustomButton from '../../util/CustomButton';


//icons
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

const styles = (theme) => ({
    ...theme.spreadIt,
    submitButton:{
        position:'relative',
        float:'right',
        marginTop:10
    },
    progressSpinner:{
        position:'absolute'
    },
    closeButton:{
        position:'absolute',
        top:'6%',
        left:'91%'
    }
  });

class PostShout extends Component {
    state={
        open:false,
        body:'',
        content:'',
        errors:{}
    };
    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({
                errors:nextProps.UI.errors
            });
        };
        if(!nextProps.UI.errors && !nextProps.UI.loading){
            this.setState({body:'',open:false,errors:{}});
        }
    }
    handleOpen=()=>{
        this.setState({open:true});
    }
    handleClose=()=>{
        this.props.clearErrors();
        this.setState({open:false,errors:{}});
    }
    handleChange=(event)=>{
        this.setState({[event.target.name]:event.target.value});  
    }
    handleSubmit=(event)=>{
        event.preventDefault();
        this.props.postShout({body:this.state.body,content:this.state.content})
        //this.handleClose();
    }
    render() {
        const {errors}=this.state;
        const {classes,UI:{loading}}=this.props
        return (
            <Fragment>
                <CustomButton onClick={this.handleOpen} tip="Post a Shout!">
                    <AddIcon/>
                </CustomButton>
            <Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="sm">
                <CustomButton tip="close" onClick={this.handleClose} tipClassName={classes.closeButton}>
                    <CloseIcon />
                </CustomButton>
                <DialogTitle>
                    Post a new Shout
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <TextField name="body" type="text" label="Title" 
                        placeholder="Title" error={errors.body?true:false} 
                        helperText={errors.body}
                        className={classes.textField}
                        onChange={this.handleChange}
                        fullWidth/>

                        <TextField name="content" type="text" label="Content" multiline rows="5" 
                        placeholder="Accepts Markdown also" error={errors.body?true:false} 
                        helperText={errors.content}
                        className={classes.textField}
                        onChange={this.handleChange}
                        fullWidth/>
                        <Button type="submit" variant="contained" color="primary"
                        className={classes.submitButton} disabled={loading}>
                            Submit
                            {loading && (
                                <CircularProgress size={30} className={classes.progressSpinner}/>
                            )}
                        </Button>
                    </form>
                </DialogContent>

            </Dialog>
            
            </Fragment>
        )
    }
}

PostShout.propTypes={
    postShout:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired
}
const mapStateToProps=(state)=>({
    UI:state.UI
})


export default connect(mapStateToProps,{postShout,clearErrors})(withStyles(styles)(PostShout))
