import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';

//MUI
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';

//Redux
import {connect} from 'react-redux';

//components
import CustomButton from '../../util/CustomButton';
import DeteleShout from './DeleteShout';
import LikeButton from './LikeButton';
import ShoutDialog from './ShoutDialog';

//icons
import ChatIcon from '@material-ui/icons/Chat';

const styles ={
    root: {
        position:'relative',
       // minWidth: 345,
        marginBottom:20
      },
    image:{
        minWidth:200
    },
    padd: {
        paddingLeft: 25,
        objectFit:'coverage'
      },
    content:{
        padding:25,
        objectFit:'coverage'
    }
}

class Shout extends Component {


    render() {
        dayjs.extend(relativeTime);
        const {classes, shout:{body,createdAt,userImage,userHandle,shoutId,likeCount,commentCount},
                user:{authenticated,credentials:{handle}}}=this.props
        
        const deleteButton=authenticated && userHandle === handle ?(
            <DeteleShout shoutId={shoutId}/>
        ):null
        return (
            <Card className={classes.root}>
                <CardHeader avatar={<Avatar aria-label="recipe" src={userImage}/>} component={Link} to={`/user/${userHandle}`} 
                        title={userHandle}
                        subheader={
                            <Typography variant="body2" color="textSecondary">{dayjs(createdAt).fromNow()}</Typography>
                            }/>
                            {deleteButton}
                    <CardContent >
                        
                        <Typography component={Link} to={`/shout/${shoutId}`}
                         className={classes.padd} variant="body1">{body}</Typography>
                        <ShoutDialog shoutId={shoutId} userHandle={userHandle} openDialog={this.props.openDialog}/>
                    </CardContent>
                    <CardActions disableSpacing>
                        <LikeButton shoutId={shoutId}/>
                        <span>{likeCount} Likes</span>
                        <CustomButton tip="comments">
                            <ChatIcon color="primary"/>
                        </CustomButton>
                        <span>{commentCount} comments</span>
                    </CardActions>
            </Card>
        )
    }
}

Shout.propTypes={
    user:PropTypes.object.isRequired,
    shout:PropTypes.object.isRequired,
    classes:PropTypes.object.isRequired,
    openDialog:PropTypes.bool
}

const mapStateToProps =state =>({
    user:state.user
})


export default connect(mapStateToProps)(withStyles(styles)(Shout));
