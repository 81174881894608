import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';

//Redux
import {connect} from 'react-redux';
import {getShout,clearErrors} from '../../redux/actions/dataActions';

//MUI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//components
import CustomButton from '../../util/CustomButton';
import Comments from './Comments';
import CommentForm from './CommentForm';

//icons
import CloseIcon from '@material-ui/icons/Close';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import ChatIcon from '@material-ui/icons/Chat';
import LikeButton from './LikeButton';

const styles = (theme) => ({
    ...theme.spreadIt,
    profileImage:{
        maxWidth:300,
        height:200,
        borderRadius:'50%',
        objectFit:'cover'
    },
    dialogContent:{
        padding:20
    },
    closeButton:{
        position:'absolute',
        left:'90%'
    },
    expandButton:{
        position:'absolute',
        left:'90%'
    },
    spinnerDiv:{
        textAlign:'center',
        marginTop:50,
        marginBottom:50
    }
  });

class ShoutDialog extends Component {
    state ={
        open:false,
        oldPath:'',
        newPath:''
    };
    componentDidMount(){
        if(this.props.openDialog){
            this.handleOpen();
        }
    }
    handleOpen=()=>{
        let oldPath=window.location.pathname;
        const {userHandle,shoutId}=this.props;
        const newPath=`/user/${userHandle}/shout/${shoutId}`;
        if(oldPath===newPath) oldPath=`/user/${userHandle}`;
        window.history.pushState(null,null,newPath);
        this.setState({open:true,oldPath,newPath});
        this.props.getShout(this.props.shoutId);
    }
    handleClose=()=>{
        window.history.pushState(null,null,this.state.oldPath);
        this.setState({open:false});
        this.props.clearErrors();
    }
    render() {
        const {classes,shout:{shoutId,body,createdAt,likeCount,commentCount,userImage,userHandle,comments},
                UI:{loading}}=this.props;
        const dialogMarkup  =loading?(
            <div className={classes.spinnerDiv}>
            <CircularProgress size={200} thickness={2}/>
            </div>
        ):(
            <Grid container spacing={2}>
                <Grid item sm={5}>
                    <img src={userImage} alt="Profile" className={classes.profileImage}/>
                </Grid>
                <Grid item sm={7}>
                    <Typography component={Link} color="primary" variant="h5" to={`/users/${userHandle}`}>
                        @{userHandle}
                    </Typography>
                    <hr className={classes.invisibleSeparator}/>
                    <Typography color="textSecondary" variant="body2">
                        {dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
                    </Typography>
                    <hr className={classes.invisibleSeparator}/>
                    <Typography variant="body1">
                        {body}
                    </Typography>
                    <LikeButton shoutId={shoutId}/>
                    <span>{likeCount} Likes</span>
                    <CustomButton tip="comments">
                        <ChatIcon color="primary"/>
                    </CustomButton>
                    <span>{commentCount} comments</span>
                </Grid>
                <hr className={classes.visibleSeparator}/>
                <CommentForm shoutId={shoutId}/>
                <Comments comments={comments}/>
            </Grid>
        );
        return (
           <Fragment>
               <CustomButton onClick={this.handleOpen} tip="Expand Shout" tipClassName={classes.expandButton}>
                    <UnfoldMore color="primary"/>
               </CustomButton>
               <Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="sm">
                <CustomButton tip="close" onClick={this.handleClose} tipClassName={classes.closeButton}>
                    <CloseIcon />
                </CustomButton>
                <DialogContent className={classes.DialogContent}>
                    {dialogMarkup}
                </DialogContent>
               </Dialog>
           </Fragment>
        )
    }
}

ShoutDialog.propTypes={
    clearErrors:PropTypes.func.isRequired,
     getShout:PropTypes.func.isRequired,
     shoutId:PropTypes.string.isRequired,
     userHandle:PropTypes.string.isRequired,
     shout:PropTypes.object.isRequired,
     UI:PropTypes.object.isRequired
}

const mapStateToProps =state =>({
    shout:state.data.shout,
    UI:state.UI
});

const mapActionToProps={
    getShout,
    clearErrors
};
export default connect(mapStateToProps,mapActionToProps)(withStyles(styles)(ShoutDialog))
