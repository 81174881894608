import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {Link} from 'react-router-dom';

//MUI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import { CardContent } from '@material-ui/core';

const styles = (theme) => ({
    ...theme.spreadIt,
    commentData:{
        marginLeft:20
    },
    content:{
        minWidth:550,
        paddingTop:0
    }
})

class Comments extends Component {
    render() {
        const {comments,classes}=this.props;
        return (
            <Grid container>
                {comments.map((comment,index)=>{
                    const {body,createdAt,userImage,userHandle} = comment;
                    return(
                        <div className={classes.comment}  key={createdAt}>
                            <CardHeader 
                                avatar={
                                <Avatar aria-label="recipe" src={userImage} component={Link} to={`/user/${userHandle}`} />} 
                                title={
                                    <Typography variant="body2" color="primary">
                                        {userHandle}
                                    </Typography>}
                                subheader={<Typography variant="body2" color="textSecondary">
                                    {dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
                                    </Typography>
                            }/>
                            
                            <CardContent className={classes.content}>
                            <br/>
                           
                            {body}
                            </CardContent>
                                            
                            {index!==comments.length-1 && <hr className={classes.visibleSeparator}/>}
                        </div>    
                        )
                })}
            </Grid>
        )
    }
}


Comments.propTypes={
    comments:PropTypes.array.isRequired
}



export default withStyles(styles)(Comments)
