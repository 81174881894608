import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import AppIcon from '../images/logo.png';
import {Link} from 'react-router-dom'


import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//Redux
import {connect} from 'react-redux';
import {loginUser} from '../redux/actions/userActions';


const styles = (theme) => ({
    ...theme.spreadIt
  });


class login extends Component {
    constructor(){
        super();
        this.state={
            email:'',
            password:'',
            errors:{}
        }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({
                errors:nextProps.UI.errors
            })
        }
    }
    handleSubmit = (event)=>{
        event.preventDefault() 
        const userData={
            email:this.state.email,
            password:this.state.password
        }
       this.props.loginUser(userData,this.props.history);
    };
    handleChange=(event)=>{
        this.setState({
            [event.target.name] : event.target.value
        })
    };
    render() {
        const {classes,UI:{loading}} =this.props
        const{ errors}=this.state
        return (
            <Grid container className={classes.form}>
                <Grid item sm/>
                <Grid item sm>
                    <Card className={classes.card}>
                    <img src={AppIcon} alt="whizpath" className={classes.image}/>
                    <Typography variant="h2" className={classes.pageTitle}>Login</Typography>
                    <form noValidate onSubmit={this.handleSubmit}>
                    <TextField id="email" name="email" type="email" label="Email" className={classes.textField}
                        value={this.state.email} onChange={this.handleChange} helperText={errors.email} 
                        error={errors.email?true:false} fullWidth/>
                    <TextField 
                        id="password" 
                        name="password" 
                        type="password" 
                        label="Password" 
                        className={classes.textField}
                        value={this.state.password} 
                        onChange={this.handleChange} 
                        helperText={errors.password} 
                        error={errors.password?true:false} 
                        fullWidth/>

                    {errors.general && (
                        <Typography variant="body1" className={classes.customError}>
                             {errors.general}
                        </Typography>
                    )}
                    <Button 
                        type="submit" 
                        variant="contained" 
                        className={classes.button} 
                        disabled={loading}
                        color="primary">Login
                    {loading &&(<CircularProgress size={30} className={classes.progress}></CircularProgress>)}</Button>
                    <br />
                    <small >dont have an account ? Create one <Link to="/signup">here</Link> </small>
                    </form>
                    </Card>
                </Grid>
                <Grid item sm/>
            </Grid>
        )
    }
}

login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
  };
  

const mapStateToProps=(state)=>({
    user:state.user,
    UI:state.UI
})

const mapActionToProps={
    loginUser
};
export default connect(mapStateToProps,mapActionToProps)(withStyles(styles)(login))
