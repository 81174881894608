import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

//components
import CustomButton from '../../util/CustomButton';

//icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

//Redux
import {connect} from 'react-redux';
import {likeShout,unlikeShout} from '../../redux/actions/dataActions';

class LikeButton extends Component {
    
    likedShout=()=>{
        if(this.props.user.likes && this.props.user.likes.find(like=>like.shoutId===this.props.shoutId))
            return true;
            else return false;
    }
    likeShout=()=>{
        this.props.likeShout(this.props.shoutId);
    }
    
    unlikeShout=()=>{
        this.props.unlikeShout(this.props.shoutId);
    }
    render() {
        const {authenticated}=this.props.user;
        const likeButton= !authenticated?(
            <Link to="/login">
            <CustomButton tip="Like">
                <FavoriteBorder color="primary"/>
            </CustomButton>
             </Link>
        ):(
            this.likedShout()?(
                <CustomButton tip="unlike" onClick={this.unlikeShout}>
                    <FavoriteIcon color="primary"/>
                </CustomButton>
            ):(
                <CustomButton tip="like" onClick={this.likeShout}>
                    <FavoriteBorder color="primary"/>
                </CustomButton>
            )
        );
        return likeButton;
    }
}
LikeButton.propTypes={
    user:PropTypes.object.isRequired,
    shoutId:PropTypes.string.isRequired,
    likeShout:PropTypes.func.isRequired,
    unlikeShout:PropTypes.func.isRequired
}

const mapStateToProps=(state)=>({
    user:state.user
})

const mapActionsToProps={
    likeShout,
    unlikeShout
}
export default connect(mapStateToProps,mapActionsToProps)(LikeButton);
