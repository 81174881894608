import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import jwtDecode from 'jwt-decode';

//Redux

import {Provider} from 'react-redux';
import store from './redux/store';
import {SET_AUTHENTICATED} from './redux/type';
import {logoutUser,getUserData} from './redux/actions/userActions';
import axios from 'axios';

//util
import themeFile from './util/theme.js';
import AuthRoute from './util/AuthRoute.js';

//components
import Navbar from './components/layout/Navbar';


//Pages
import home from './pages/home';
import signup from './pages/signup';
import login from './pages/login';
import user from './pages/user';
import shout from './pages/shout';

const theme=createMuiTheme(themeFile);

axios.defaults.baseURL="https://us-central1-shoutout-8b03c.cloudfunctions.net/api";

const token =localStorage.FBIdToken;
if(token){
  const decodedToken=jwtDecode(token);
  if(decodedToken.exp*1000<Date.now()){
    store.dispatch(logoutUser())
    window.location.href='/login';
  }else{
    store.dispatch({type:SET_AUTHENTICATED});
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
        <Navbar />
        <div className="container">
          <Switch>
            <Route exact path="/" component={home}/>
            <AuthRoute exact path="/login" component={login}/>
            <AuthRoute exact path="/signup" component={signup}/>
            <Route exact path="/user/:handle" component={user}/>
            <Route exact path="/shout/:shoutId" component={shout}/>
            <Route exact path="/user/:handle/shout/:shoutId" component={user}/>
          </Switch>
        </div>
        </Router>
      </Provider>
    </MuiThemeProvider>
  
  );
}

export default App;
