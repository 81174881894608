import React, { Component ,Fragment} from 'react'
import PropTypes from 'prop-types';

//MUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';

//Redux
import {connect} from 'react-redux';
import {deleteShout} from '../../redux/actions/dataActions';

//components
import CustomButton from '../../util/CustomButton';

//icons
import DeleteOutline from '@material-ui/icons/DeleteOutline';

const styles = (theme) => ({
    ...theme.spreadIt,
    deleteButton:{
        position:'absolute',
        top:'10%',
        left:'90%'
    }
  });

class DeleteShout extends Component {
    state={
        open:false
    };

    handleOpen = () =>{
        this.setState({open:true});
    }
    handleClose = () =>{
        this.setState({open:false});
    }
    deleteShout = () =>{
        this.props.deleteShout(this.props.shoutId);
        this.setState({open:false});
    }
    render() {
        const {classes} =this.props;

        return (
            <Fragment>
                <CustomButton tip="Delete Shout" onClick={this.handleOpen} btnClassName={classes.deleteButton}>
                    <DeleteOutline color="secondary"/>
                </CustomButton>
                <Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="sm">
                    <DialogTitle>
                        Do your want to delete this shout?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.deleteShout} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

DeleteShout.propTypes={
    deleteShout:PropTypes.func.isRequired,
    classes:PropTypes.object.isRequired,
    shoutId:PropTypes.string.isRequired
}

export default connect(null,{deleteShout})(withStyles(styles)(DeleteShout));
