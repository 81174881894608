import React, { Component, Fragment } from 'react'
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import dayjs from 'dayjs';

//Redux
import {connect} from 'react-redux';
import {getShout} from '../redux/actions/dataActions';
import {getUserInfo} from '../redux/actions/userActions';
//MUI
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import relativeTime from 'dayjs/plugin/relativeTime';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProfileSkeleton from '../util/ProfileSkeleton';
import StaticProfile from '../components/profile/StaticProfile';


//Components
import Comments from '../components/shout/Comments';


const styles = (theme) => ({
    ...theme.spreadIt,
    content:{
        padding:20
    }
})

class shout extends Component {
    
    constructor(props) {
        super(props);
        const shoutId= this.props.match.params.shoutId;
        this.props.getShout(shoutId);
        this.props.UI.loading=true;
    }

    state={
        profile:null,
        shoutIdParam:null,
        profileLoaded:false
    }

    componentDidMount(){
        const shoutId= this.props.match.params.shoutId;
        if(shoutId) this.setState({shoutIdParam:shoutId});
    }
    componentWillReceiveProps(nextProps){
        
        if(this.props.shout.userHandle && !this.state.profileLoaded){
            this.props.getUserInfo(this.props.shout.userHandle);
            this.setState({profileLoaded:true})
        }
    }
    render() {
        
        dayjs.extend(relativeTime);
        const {classes,shout:{body,content,createdAt,userImage,comments},
                UI:{loading}}=this.props;
        const markdown=`${content}`;
        const contentMarkdown=loading?<p>Loading</p>:(
            <Fragment>
                <Paper className={classes.content}>
            <CardHeader avatar={<Avatar aria-label="recipe" src={userImage}/>} 
                title={body}
                subheader={
                    <Typography variant="body2" color="textSecondary">{dayjs(createdAt).fromNow()}</Typography>
                    }/>
                <Markdown>
                    {markdown}
                </Markdown>
            </Paper>
            <br/>
            <Comments comments={comments}/>
            </Fragment>);
         const test=this.props.userInfo &&this.props.userInfo.user ?(<StaticProfile profile={this.props.userInfo.user}/>):(
            <ProfileSkeleton/>
         );
        return (
            <Grid container spacing={2}>
                <Grid item sm={8} xs={12}>
                    {contentMarkdown}
                </Grid>
                <Grid item sm={4} xs={12}>
                   {test}
                </Grid>
            </Grid>
        )
    }
}
shout.propTypes={
    getUserInfo:PropTypes.func.isRequired,
    getShout:PropTypes.func.isRequired,
    shout:PropTypes.object.isRequired,
    UI:PropTypes.object.isRequired
}

const mapStateToProps =state =>({
    shout:state.data.shout,
    userInfo:state.user.userInfo,
    UI:state.UI
});

export default connect(mapStateToProps,{getShout,getUserInfo})(withStyles(styles)(shout))

